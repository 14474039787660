import moment from "moment-timezone";

export const searchDateFormat = "YYYY-MM-DD";
export const parseDateFormat = "M/dd/yyyy h:mm:ss a";
export const displayDateFormat = "MMM DD, YYYY";
export const displayTimeFormat = "DD MMM hh:mm A";
export const displayDate = "D";
export const displayMonth = "MMM";

export const resParseDateFormat = "MM/DD/YYYY h:mm:ss a";

export const formatDate = (
  date: Date | undefined | 0 | string,
  dteFormat: string /* , locale?: string */
): string => moment(date).format(dteFormat); // format(date, dteFormat, { locale });

export const formatDateInToUTC = (
  date: Date | undefined | 0 | string,
  dteFormat: string /* , locale?: string */
): string => moment.utc(date).format(dteFormat);
